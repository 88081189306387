import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from "react-table";
import clsx from "clsx";
import SyncByDateFilter from "./SyncByDateFilter";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";
import {
  downloadSampleInventoryFile,
  getDeskeraTenantDetails,
  importRelativePath,
  uploadInventoryFile,
  getCartConfig,
  populateShopifyOrders as syncShopifyOrdersToEcom,
  populateShopifyProducts as syncShopifyProductsToEcom,
} from "src/api";
import Moment from "moment";
import { startDateFormat, yearFirstDateFormat } from "src/utils/Constants";
import {
  DKButton,
  DKDateRangePicker,
  DKIcons,
  DKSpinner,
  getDateAsString,
  showAlert,
} from "deskera-ui-library";
import { Checkbox } from "./Checkbox";
import CloudUpload from "src/assets/CloudUpload";
import { useRef } from "react";
import FileIcon from "src/assets/FileIcon";
import ic_calendar from "src/assets/ic_calendar.png";
import SquareProductSyncConfig from "src/pages/Square/ProductSyncConfig";
import MagentoProductSyncConfig from "src/pages/Magento/ProductSyncConfig";
import MapBigCommerceProducts from "src/pages/Bigcommerce/MapBigCommerceProducts";
import {
  saveMagentoMatchingProductsToECom,
  saveMagentoMatchingCustomersToECom,
  saveMagentoMatchingOrdersToECom,
  saveBigCommerceMatchingProductsToECom,
  saveBigCommerceMatchingCustomersToECom,
  saveBigCommerceMatchingOrdersToECom,
} from "src/api";
import SearchFilter from "./Filters/SearchFilter";
import SearchBar from "./Filters/SearchBar";
import { Utility } from "src/utils/Utility";
import SyncByDateRange from "./SyncByDateRange";
import moment from "moment";
import SyncByStores from "./SyncByStores";
import MapShopifyProducts from "src/pages/Shopify/MapShopifyProducts";
import ShopifyStoreFilter from "./Shopify/ShopifyStoreFilter";

const SELECT_ALL_STORES_ID = "Select All Stores";
const SELECT_ALL_STORES = {
  name: "All",
  id: SELECT_ALL_STORES_ID,
}
function ServerPaginationTable({
  columns,
  data,
  migrate,
  cart,
  refresh,
  minHeight,
  tab,
  pageCount: controlledPageCount,
  loading,
  rowId,
}) {
  const [value, setValue] = useState();
  const [status, setStatus] = useState([]);
  const uploadInput = useRef();
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [hover, setHover] = useState(false);
  const job = useSelector((state) => {
    const cartState = state[cart];
    if (cartState && cartState.job && cartState.job.data) {
        return cartState.job.data;
    }
    return null;
});
  const jobRunning =
  job && (job.jobStatus === "QUEUED" || job.jobStatus === "IN_PROGRESS") ? true : false;
  const [triggered, setTriggered] = useState(false);
  const [triggeredEcom, setTriggeredEcom] = useState(false);
  const [uploadTriggered, setUploadTriggered] = useState(false);
  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const dispatch = useDispatch();
  const [startDateObj, setStartDateObj] = useState();
  const [endDateObj, setEndDateObj] = useState();
  const tenantInfo = useSelector((state) => state.auth.user);
  const [loadConfigOpen, setLoadConfigOpen] = useState(false);
  const [filterDates, setFilterDates] = useState(undefined);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [syncConfigOpen, setSyncConfigOpen] = useState(false);
  const [syncSquareConfigOpen, setSquareSyncConfigOpen] = useState(false);
  const [syncBigCommerceOrdersToEcomConfigOpen, setSyncBigCommerceOrdersToEcomConfigOpen] =
    useState(false);
  const [syncBigCommerceOrdersConfigOpen, setSyncBigCommerceOrdersConfigOpen] = useState(false);
  const [syncMagentoConfigOpen, setMagentoSyncConfigOpen] = useState(false);
  const [syncBigCommerceConfigOpen, setBigCommerceConfigOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const amazonConfig = useSelector((state) => state.amazon.config);
  const wooCommerceConfig = useSelector((state) => state.woocommerce.config);
  const squareConfig = useSelector((state) => state.square.config);
  const tallyConfig = useSelector((state) => state.tally.config);
  const bigCommerceConfig = useSelector((state) => state.bigcommerce.config);
  const shipStationConfig = useSelector((state) => state.shipStation.config);
  const shopifyConfig = useSelector((state) => state.shopify.config);
  const magentoConfig = useSelector((state) => state.magento.config);
  const brightPearlConfig = useSelector((state) => state.brightpearl.config);

  const [prevPageIndex, setPrevPageIndex] = useState(0);
  const [fulfillShipStationTrigger, setFulFillShipStationTrigger] = useState(false);
  const [apiStatuses, setApiStatuses]  = useState({
    syncShopifyProductsToEcom: false,
    syncShopifyOrderToEcom: false,
  });
  const [query, setQuery] = useState({});
  const accounts = useSelector((state) => state.shopify.accounts);
  const shopifyConnectedStores = useSelector((state) => state.shopify.accounts);
  const [syncDateRange, setSyncDateRange] = useState(undefined);
  const [showSyncFromDate, setShowSyncFromDate] = useState(false);
  const [showSyncByStores, setShowSyncByStores] = useState(false);
  const [selectedStores, setSelectedStores] = useState([...shopifyConnectedStores.data.map((store) => store.id), SELECT_ALL_STORES_ID]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
      autoResetSelectedRows: false,
      getRowId: (row) => row[rowId],
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({
            getToggleAllPageRowsSelectedProps,
            toggleRowSelected,
            rows,
            selectedFlatRows,
          }) => {
            const { onChange, style, ...otherProps } = getToggleAllPageRowsSelectedProps();
            const getSelectableRows = rows.filter(
              (row) =>
                row.original.syncStatus !== "SUCCESSFUL" && row.original.syncStatus !== "FULFILLED"
            );

            // Override onChange
            const onChangeOverride = (event) => {
              getSelectableRows.forEach((row) => {
                toggleRowSelected(row.id, event.currentTarget.checked);
              }
              );
            };

            const checked =
              getSelectableRows.length === selectedFlatRows.length && getSelectableRows.length > 0;
            const indeterminate = selectedFlatRows.length > 0 && !checked;
            const disabled =
              getSelectableRows.length === 0 || (cart === "magento" && tab === "customFields");
              const newProps = {
              onChange: onChangeOverride,
              style: style,
              checked: checked,
              indeterminate: indeterminate,
              disabled: disabled,
            };
            return !(cart === "magento" && tab === "customFields") && <Checkbox {...newProps} />;
          },

          Cell: ({ row }) => (
            <div>
              {!(cart === "magento" && tab === "customFields") && (
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  disabled={
                    row.original.syncStatus === "SUCCESSFUL" ||
                    row.original.syncStatus === "FULFILLED" ||
                    (cart === "magento" && tab === "customFields")
                  }
                />
              )}
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const [initailRefreshOptions] = useState({
    pageIndex,
    pageSize,
    sortBy,
    selectedRowIds,
    status,
  });
  const colummsLength = columns.length;
  const dataLength = data.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const showBrightPearlSyncOrdersButton = useMemo(() => {
    const isSalesOrderEnabled = !!tenantInfo?.additionalSettings?.ENABLE_SO;
       if(cart!=="brightpearl"){
         return true;
       }else{
        if(tab==="Orders"){
          return isSalesOrderEnabled;
        }else{
          return true;
        }
       }

  }, [tenantInfo]);




  const hideProducts = useMemo(() => {
    let hideProducts = {};
    const isShopifyProductsEnabled = !!shopifyConfig?.data?.hideProducts;
    const isBigCommerceProductsEnabled = !!bigCommerceConfig?.data?.hideProducts;
    const isWooCommerceProductsEnabled = !!wooCommerceConfig?.data?.hideProducts;
    const isSquareProductsEnabled = !!squareConfig?.data?.hideProducts;
    const isTallyProductsEnabled = !!tallyConfig?.data?.hideProducts;
    const isAmazonProductsEnabled = !!amazonConfig?.data?.hideProducts;
    const isShipStationProductsEnabled = !!shipStationConfig?.data?.hideProducts;
    const isBrightPearlProductsEnabled = !!brightPearlConfig?.data?.hideProducts;

    hideProducts = {
      shopify: isShopifyProductsEnabled,
      bigcommerce: isBigCommerceProductsEnabled,
      woocommerce: isWooCommerceProductsEnabled,
      square: isSquareProductsEnabled,
      tally: isTallyProductsEnabled,
      amazon: isAmazonProductsEnabled,
      shipStation: isShipStationProductsEnabled,
      brightpearl: isBrightPearlProductsEnabled,
    };

    debugger;
    let showSyncProductsBtn = true;
    if(tab==='Products'){
      if(hideProducts?.[cart]){
        showSyncProductsBtn = false
      }
    }
    return {
      hideProducts,
      showSyncProductsBtn: showSyncProductsBtn,
    };
  }, [
    cart, 
    tab,
    shopifyConfig,
    magentoConfig,
    bigCommerceConfig,
    wooCommerceConfig,
    squareConfig,
    tallyConfig,
    amazonConfig,
    shipStationConfig,
    brightPearlConfig,
  ]);
  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const statusOptions = [
    {
      value: "SUCCESSFUL",
      label: "Successful",
    },
    {
      value: "FAILED",
      label: "Failed",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
    {
      value: "PARTIAL",
      label: "Partial",
    },
    {
      value: "FULFILLED",
      label: "Fulfilled",
    },
  ];

    const tallyFilterStatusOption = [
      {
        value: "SUCCESSFUL",
        label: "Successful",
      },
      {
        value: "FAILED",
        label: "Failed",
      },
      {
        value: "PENDING",
        label: "Pending",
      },
      {
        value: "PARTIAL",
        label: "Partial",
      }
    ];

  const [search, setSearch] = useState(null);



  useEffect(() => {
    const storeIds = shopifyConnectedStores.data.map((store) => store.id);
    setSelectedStores([...storeIds, SELECT_ALL_STORES_ID]);
  }, [shopifyConnectedStores]);

  useEffect(() => {
    if (cart === "shopify" && tab === "Orders") {
      const financialDates = getFinancialStartEndDate();
      setFilterDates({
        startDate: financialDates.startDate,
        endDate: financialDates.endDate,
      });
      const bbDate = tenantInfo?.bookBeginningStartDate;
      if (bbDate) {
        setSyncDateRange(new Date(bbDate));
      }
    }
  }, []);

  useEffect(() => {
    if (search != null || Object.keys(query)?.length) {
      let financialDates = filterDates;

      if (!financialDates) {
        financialDates = getFinancialStartEndDate();
      }

      setFilterDates({
        startDate: financialDates.startDate,
        endDate: financialDates.endDate,
      });

      let { startDate, endDate } = getDateRange(financialDates);
      refresh({ pageIndex: 0, status, pageSize, sortBy, search, query, startDate, endDate });
      gotoPage(0);
    }
  }, [search, query]);

  useEffect(() => {
    if (
      pageIndex !== prevPageIndex ||
      pageIndex !== initailRefreshOptions.pageIndex ||
      pageSize !== initailRefreshOptions.pageSize ||
      sortBy !== initailRefreshOptions.sortBy ||
      selectedRowIds !== initailRefreshOptions.selectedRowIds
    ) {
      if (cart === "shopify" && tab === "Orders") {
        refreshShopifyOrder();
      } else if (cart === "magento" || cart === "bigcommerce" || cart === "shopify" || cart === "tally") {
        refresh({ pageIndex, status, pageSize, sortBy, search, query });
      } else {
        refresh({ pageIndex, pageSize, sortBy, search, query });
      }
      setPrevPageIndex(pageIndex);
    }
  }, [pageIndex, pageSize, sortBy]);

  function refreshData() {
    if (cart === "shopify" && (tab === "Orders" || tab === "Customers")) {
      refreshShopifyOrder();
    } else {
      refresh({ pageIndex, pageSize, sortBy, query });
    }
  }
  // update pageIndex to 0 whenever status updated
  useEffect(() => {
    if (initailRefreshOptions.status !== status) {
      if (cart === "shopify" && tab === "Orders") {
        refreshShopifyOrder(true);
      } else {
        refresh({ pageIndex: 0, pageSize, status, search, query });
      }
      gotoPage(0);
    }
  }, [status]);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "195px",
    }),
  };

  const updateSelectedStatus = (options) => {
    var selected = [];
    options.forEach((element) => {
      selected.push(element.value);
    });
    setStatus(selected);
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onFilter = (query) => {
    const search = encodeURIComponent(query["search"] ?? "");
    setSearch(search);
  };

  const onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    setIsFileSelected(true);
    setSelectedFile(files[0]);
    // handleFiles(files);
  };

  const onUploadInputClicked = (event) => {
    const files = event.target.files;
    setIsFileSelected(true);
    setSelectedFile(files[0]);
    // handleFiles(selectedFiles);
  };

  function handleCloseFileModal() {
    setFileModalOpen(false);
    setIsFileSelected(false);
    setSelectedFile({});
  }

  // Calls backend and triggers sample file download
  async function triggerDownload() {
    setDownloadTriggered(true);
    try {
      const sampleFile = await downloadSampleInventoryFile();
      const fileName = "update_shopify_product_initial_quantity.csv";
      let blobContent = new Blob([sampleFile], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadAnchor = document.createElement("a");
      downloadAnchor.setAttribute("download", fileName);
      /* Supporting IE */
      if (blobContent && navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blobContent, fileName);
      } else if (downloadAnchor.download !== undefined) {
        /* Checking If download feature supported in browser */
        downloadAnchor.href = blobContent ? URL.createObjectURL(blobContent) : sampleFile;
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setDownloadTriggered(false);
    }
  }

  async function handleImportFile() {
    setUploadTriggered(true);
    const extension = selectedFile.name.match(/\.[^/.]+$/);
    if (extension[0] != ".csv") {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Only .csv files are allowed",
        })
      );
      setUploadTriggered(false);
      return;
    }
    let uploadResp;
    try {
      const fileToUpload = new File([selectedFile], selectedFile.name);
      const uploadFileData = new FormData();
      uploadFileData.append("file", fileToUpload);
      uploadFileData.append("file-entity", "IMPORT_SHOPIFY_PRODUCT_OPENING");
      uploadResp = await uploadInventoryFile(uploadFileData);
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to upload attachment",
        })
      );
      console.log(err);
      setUploadTriggered(false);
      return;
    }

    try {
      const importResp = await importRelativePath(uploadResp.relativePath);
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to import relative path",
        })
      );
      console.log(err);
      setUploadTriggered(false);
      return;
    }

    setUploadTriggered(false);
    handleCloseFileModal();
    dispatch(
      addToast({
        type: "success",
        title: "Success",
        message: "Inventory uploaded successfully",
      })
    );
  }

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDateObj(start);
    setEndDateObj(end);
  };

  function fileUploadModal() {
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            className="card dk-card mb-4 d-flex justify-content-center p-2"
            style={{ minWidth: "40%" }}
          >
            <div className="modal-header p-1">
              <h5 className="modal-title text-muted">Import Inventory file</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseFileModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="card-body d-flex flex-column justify-content-center bg-gray1 border-m border-radius-s align-items-center p-v-r p-h-l m-2 cursor-hand"
              style={{ minHeight: "200px" }}
              onDragEnter={onDragOver}
              onDragOver={onDragOver}
              onDrop={onFileDrop}
            >
              <span className="svg-disabled align-self-center m-2">
                {isFileSelected ? <FileIcon /> : <CloudUpload />}
              </span>

              <div className="row justify-content-center bg-button fw-m text-muted mb-2">
                {isFileSelected ? selectedFile.name : "Drop your .csv file here"}
              </div>
              <div className="row text-center justify-content-center">
                <button
                  className="btn col-md-auto btn-secondary m-1"
                  disabled={downloadTriggered}
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={triggerDownload}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {downloadTriggered ? (
                      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    ) : (
                      <></>
                    )}
                  </span>
                  Download sample csv
                </button>

                <button
                  className="btn col-md-auto btn-primary m-1"
                  onClick={() => {
                    isFileSelected ? handleImportFile() : uploadInput.current.click();
                  }}
                  disabled={uploadTriggered}
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {uploadTriggered ? (
                      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    ) : (
                      <></>
                    )}
                  </span>
                  {isFileSelected ? "Upload File" : "Select file"}
                </button>
                <input
                  className="parent-width parent-height position-absolute"
                  style={{ visibility: "hidden" }}
                  type="file"
                  accept=".csv,.xlsx,text/csv"
                  onChange={onUploadInputClicked}
                  ref={uploadInput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function refreshShopifyOrder(statusUpdated = false) {
    let financialDates = filterDates;
    if (!financialDates || !financialDates.startDate) {
      financialDates = getFinancialStartEndDate();
    }
    const dates = {
      startDate: financialDates.startDate,
      endDate: financialDates.endDate,
    };
    let { startDate, endDate } = getDateRange(dates);
    refresh({
      pageIndex: statusUpdated ? 0 : pageIndex,
      pageSize,
      status,
      startDate,
      endDate,
      search, 
      query
    });
  }

  async function handleSyncSubmit(syncType = "") {
    setSyncBigCommerceOrdersConfigOpen(false);
    if (syncType === "fulfillShipStation") {
      setFulFillShipStationTrigger(true);
    } else {
      setTriggered(true);
    }

    var startDate;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      const userInfo = cart === "square" || cart === "tally" ? null : await getCartConfig(String(cart)?.toLowerCase());

      if (!!userInfo && !!userInfo?.syncStartDate) {
        startDate = userInfo.syncStartDate;
      } else {
        startDate = tenantDetails.bookBeginningStartDate;
      }
      startDate = Moment(startDate).format(startDateFormat)
    } catch (err) {
      if (syncType === "fulfillShipStation") {
        setFulFillShipStationTrigger(false);
      } else {
        setTriggered(false);
      }
      return;
    }

    try {
      const ids = Object.entries(selectedRowIds).map((k) => k[0]);
      let body = { startDate: startDate };
      if (cart === "shopify") {
        body = {
          ...body,
          shopifyIds: ids,
          selectedStores:
            selectedStores.length > 0 ? selectedStores.filter(Utility.isValidUrl) : [],
        };
      } else if (cart === "shipStation") {
        body = { ...body, shipStationIds: ids, syncType: syncType };
      } else if (cart === "woocommerce") {
        body = { ...body, woocomIds: ids };
      } else if (cart === "square") {
        body = { ...body, squareIds: ids };
      } else if (cart === "magento" && tab === "Orders") {
        body = { ...body, magentoOrderIds: ids };
      } else if (cart === "magento") {
        body = { ...body, magentoIds: ids };
      } else if (cart === "tally") {
        body = { ...body, tallyIds: ids };
      } else if (cart === "bigcommerce") {
        body = {
          ...body,
          startDate: Moment(startDateObj).format(startDateFormat),
          endDate: Moment(endDateObj).format(startDateFormat),
          bigcommerceIds: ids,
        };
      } else if (cart === "brightpearl") {
        body = {
          ...body,
          brightpearlIds: ids,
        };
      }
      await migrate(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );
    } catch (err) {
      if (cart !== "amazon") {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data.message,
          })
        );
      }
    }
    if (cart === "shopify" && tab === "Orders") {
      const dates = {
        startDate: filterDates.startDate,
        endDate: filterDates.endDate,
      };
      let { startDate, endDate } = getDateRange(dates);
      refresh({ pageIndex, pageSize, status, startDate, endDate, query, search });
    } else {
      refresh({ pageIndex, pageSize, status, query });
    }

    if (syncType === "fulfillShipStation") {
      setFulFillShipStationTrigger(false);
    } else {
      setTriggered(false);
    }
    toggleAllRowsSelected(false);
    setLoadConfigOpen(false);
  }

  function handleSyncSubmitWithAlert() {
    const ids = Object.entries(selectedRowIds).map((k) => k[0]);
    let warningMsg = `Do you want to sync All ${tab} to Deskera?`;
    if (ids && ids.length > 0) {
      warningMsg = `Do you want to sync selected ${tab}(s) to Deskera`;
    }
    showAlert("Alert", warningMsg,
    [
      {
          title: "Continue",
          className: "bg-blue mr-r text-white",
          onClick: () => {handleSyncSubmit() }
      },
      {
          title: "Cancel",
          className: "bg-gray text-white",
          onClick: () => { }
      }
  ])
  }
  async function handleSyncProductToEcomSubmit() {
    setTriggeredEcom(true);
    setSyncBigCommerceOrdersToEcomConfigOpen(false);
    var startDate;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      const userInfo = await getCartConfig(String(cart)?.toLowerCase());

      if (!!userInfo && !!userInfo?.syncStartDate) {
        startDate = userInfo.syncStartDate;
      } else {
        startDate = tenantDetails.bookBeginningStartDate;
      }

      let body = { startDate: Moment(startDate).format(startDateFormat) };
      if (cart === "magento") {
        if (tab === "Products") {
          await saveMagentoMatchingProductsToECom(body);
        } else if (tab === "Customers") {
          await saveMagentoMatchingCustomersToECom(body);
        } else if (tab === "Orders") {
          await saveMagentoMatchingOrdersToECom(body);
        }
      } else if (cart === "bigcommerce") {
        if (tab === "Products") {
          await saveBigCommerceMatchingProductsToECom(body);
        } else if (tab === "Customers") {
          await saveBigCommerceMatchingCustomersToECom(body);
        } else if (tab === "Orders") {
          await saveBigCommerceMatchingOrdersToECom({
            ...body,
            startDate: Moment(startDateObj).format(startDateFormat),
            endDate: Moment(endDateObj).format(startDateFormat),
          });
        }
      }
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
      setTriggeredEcom(false);
      return;
    }
    refresh({ pageIndex, pageSize, status, query });
    setTriggeredEcom(false);
  }
  function getDateRange(financialDates) {
    let endDate = "";
    let startDate = "";
    if (!financialDates?.startDate || !financialDates.endDate) {
      return { startDate, endDate };
    }
    var sd = financialDates.startDate;
    var startMonth = sd.getMonth() + 1;
    var ed = financialDates.endDate ? financialDates.endDate : financialDates.startDate;
    var endMonth = ed.getMonth() + 1;
    endDate =
      ed.getUTCFullYear() +
      "-" +
      String(endMonth).padStart(2, "0") +
      "-" +
      String(ed.getDate()).padStart(2, "0");
    startDate =
      sd.getUTCFullYear() +
      "-" +
      String(startMonth).padStart(2, "0") +
      "-" +
      String(sd.getDate()).padStart(2, "0");
    return { startDate, endDate };
  }

  function getFinancialStartEndDate() {
    let bookBeginningStartDate = tenantInfo?.bookBeginningStartDate
      ? new Date(tenantInfo?.bookBeginningStartDate)
      : new Date();
    let startDate = new Date().setDate(new Date().getDate() - 7);
    startDate = startDate >= bookBeginningStartDate ? startDate : bookBeginningStartDate;
    const endDate = new Date();
    startDate = new Date(startDate);
    return { startDate, endDate };
  }

  function Warning() {
    return <div className="bg-chip-blue border-radius-l p-h-s ml-s p-v-xs">Syncing...</div>;
  }

  const setDateRangeForFilter = (financialDates) => {
    if (!financialDates) {
      financialDates = getFinancialStartEndDate();
    }

    setFilterDates({
      startDate: financialDates.startDate,
      endDate: financialDates.endDate,
    });

    let { startDate, endDate } = getDateRange(financialDates);
    refresh({ pageIndex, pageSize, status, startDate, endDate, search, query });
  };

  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-10 top-12 right-20 deskera-datepicker">
        <DKDateRangePicker
          className="w-500"
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(33, 107, 165)"}
          startDate={filterDates.startDate}
          selectedStartDate={filterDates.startDate}
          selectedEndDate={filterDates.endDate}
          onSelectDateRange={(startDate, endDate) => {
            if (startDate && endDate) {
              setDateRangeForFilter({
                startDate: startDate,
                endDate: endDate,
              });
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const getDateRangeString = () => {
    if (filterDates && filterDates.startDate) {
      return (
        getDateAsString(filterDates.startDate, "dd-MM-yyyy") +
        " to " +
        getDateAsString(filterDates.endDate, "dd-MM-yyyy")
      );
    } else {
      return "";
    }
  };

  const MagentoSyncConfig = () => {
    if (magentoConfig && magentoConfig?.data?.syncEnabled === false) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Sync is not enabled",
        })
      );
    } else {
      setMagentoSyncConfigOpen(true);
    }
  };

  return (
    <>
      {fileModalOpen && fileUploadModal()}
      {cart === "shopify" && syncConfigOpen && (
        <MapShopifyProducts setSyncConfigOpen={setSyncConfigOpen} refreshData={refreshData} />
      )}
      {syncSquareConfigOpen && (
        <SquareProductSyncConfig
          setSyncConfigOpen={setSquareSyncConfigOpen}
          refreshData={refreshData}
        />
      )}
      {syncMagentoConfigOpen && (
        <MagentoProductSyncConfig
          setSyncConfigOpen={setMagentoSyncConfigOpen}
          refreshData={refreshData}
        />
      )}
      {syncBigCommerceConfigOpen && (
        <MapBigCommerceProducts
          setSyncConfigOpen={setBigCommerceConfigOpen}
          refreshData={refreshData}
        />
      )}
      {cart === "bigcommerce" && tab === "Orders" && syncBigCommerceOrdersConfigOpen && (
        <SyncByDateFilter
          selected={startDateObj}
          onChange={onDateChange}
          startDate={startDateObj}
          endDate={endDateObj}
          isInline={true}
          selectsRange={true}
          syncSubmit={(e) => {
            handleSyncSubmit("syncShipStation");
          }}
          onClose={(e) => {
            setSyncBigCommerceOrdersConfigOpen(false);
          }}
        />
      )}
      {cart === "bigcommerce" && tab === "Orders" && syncBigCommerceOrdersToEcomConfigOpen && (
        <SyncByDateFilter
          selected={startDateObj}
          onChange={onDateChange}
          startDate={startDateObj}
          endDate={endDateObj}
          isInline={true}
          selectsRange={true}
          syncSubmit={(e) => {
            handleSyncProductToEcomSubmit();
          }}
          onClose={(e) => {
            setSyncBigCommerceOrdersToEcomConfigOpen(false);
          }}
        />
      )}
      <div className="d-flex flex-column mb-2">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            maxHeight: '40px',
            display: "flex",
          }}
        >
          {cart === "shipStation" && (
            <button
              className="btn border-radius-m p-v-s text-white bg-primary mr-2"
              onClick={() => refresh({ pageIndex, pageSize, status, query })}
              disabled={triggered}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              Refresh
            </button>
          )}
          {(cart === "magento" && tab !== "customFields") ||
            (cart === "tally" && (
              <button
                className="btn p-v-s text-white bg-success border-radius-m"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  if (cart === "shopify" && tab === "Orders") {
                    const dates = {
                      startDate: filterDates.startDate,
                      endDate: filterDates.endDate,
                    };
                    let { startDate, endDate } = getDateRange(dates);
                    refresh({ pageIndex, pageSize, status, startDate, endDate, query });
                  } else {
                    refresh({ pageIndex, pageSize, status, query });
                  }
                }}
              >
                <span className="svg-icon">
                  <ArrowClockwise />
                </span>
              </button>
            ))}
          {tab !== "MatchingSummaryTable" && cart !== "magento" && cart !== "tally" && (
            <div
              style={{
                display: hideProducts.showSyncProductsBtn === true ? "unset" : "none",
                position: "relative",
              }}
            >
              <div
                className={`d-flex border-radius-m border-m ml-1 gap-2 align-items-center bg-success`}
              >
                {triggered && <DKSpinner iconClassName="ic-s-2" className="ml-s" />}
                <DKButton
                  className="p-v-s text-white bg-success ml-s"
                  onClick={() => {
                    if (
                      Object.entries(selectedRowIds).length === 0 &&
                      shopifyConnectedStores.data.length > 1 &&
                      cart === "shopify"
                    ) {
                      setShowSyncByStores(true);
                    } else {
                      cart === "bigcommerce" && tab === "Orders"
                        ? Object.entries(selectedRowIds).length > 0
                          ? handleSyncSubmit("syncShipStation")
                          : setSyncBigCommerceOrdersConfigOpen(true)
                        : handleSyncSubmit("syncShipStation");
                    }
                  }}
                  disabled={!showBrightPearlSyncOrdersButton || triggered}
                  onMouseOver={() => setHover(true)}
                  title={
                    Object.entries(selectedRowIds).length > 0 ? (
                      <span>Sync Selected {tab}</span>
                    ) : (
                      <span>
                        {cart === "shipStation" && tab === "Orders" ? (
                          <>Sync to ShipStation</>
                        ) : (
                          <>Sync {tab}</>
                        )}
                      </span>
                    )
                  }
                  onMouseLeave={() => setHover(false)}
                  isReverse={true}
                  icon={
                    shopifyConnectedStores.data.length > 1 && cart === "shopify"
                      ? Object.entries(selectedRowIds).length > 0
                        ? null
                        : DKIcons.white.ic_arrow_down2
                      : null
                  }
                />
              </div>

              {showSyncByStores && (
                <SyncByStores
                  storeConfig={
                    [{ name: "All", id: "Select All Stores" }, ...shopifyConnectedStores.data] ?? []
                  }
                  title={"Search"}
                  onSync={() => {
                    if (selectedStores.length === 0) {
                      dispatch(
                        addToast({
                          type: "danger",
                          title: "Failure",
                          message: "Please select at least one store to sync",
                        })
                      );
                    } else {
                      cart === "bigcommerce" && tab === "Orders"
                        ? Object.entries(selectedRowIds).length > 0
                          ? handleSyncSubmit("syncShipStation")
                          : setSyncBigCommerceOrdersConfigOpen(true)
                        : handleSyncSubmit("syncShipStation");
                    }
                  }}
                  onClose={() => setShowSyncByStores(false)}
                  onSelectStore={(stores) => {
                    setSelectedStores(stores);
                  }}
                  selectedStores={selectedStores}
                />
              )}
            </div>
          )}
          {tab !== "MatchingSummaryTable" && cart === "shipStation" && (
            <button
              className={`btn border-radius-m p-v-s text-white ${
                Object.entries(selectedRowIds).length === 0 ? "bg-gray" : "bg-success"
              }`}
              onClick={() => {
                handleSyncSubmit("fulfillShipStation");
              }}
              disabled={Object.entries(selectedRowIds).length === 0 || fulfillShipStationTrigger}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{ marginLeft: "8px" }}
            >
              <span className="svg-icon svg-baseline svg-white">
                {fulfillShipStationTrigger && (
                  <div class="spinner-border text-white mr-2 spinner-border-sm" role="status"></div>
                )}
              </span>
              <span>Fulfill</span>
            </button>
          )}
          {(cart === "magento" && tab !== "customFields") ||
            (cart === "tally" && (
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={() => {
                  handleSyncSubmitWithAlert();
                }}
                disabled={triggered}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {triggered ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <></>
                  )}
                </span>
                {Object.entries(selectedRowIds).length > 0 ? (
                  <span>Sync Selected {tab}</span>
                ) : (
                  <span>Sync {tab} to Deskera</span>
                )}
              </button>
            ))}
          {((cart === "magento" && tab !== "customFields") ||
            (cart === "bigcommerce" &&
              (tab === "Products" || tab === "Customers" || tab === "Orders"))) && (
            <button
              className="btn border-radius-m ml-2 p-v-s text-white bg-success"
              onClick={() => {
                if (cart === "bigcommerce" && tab === "Orders") {
                  setSyncBigCommerceOrdersToEcomConfigOpen(true);
                } else {
                  handleSyncProductToEcomSubmit();
                }
              }}
              disabled={triggeredEcom}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {triggeredEcom ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <></>
                )}
              </span>
              Sync {tab} to Ecom
            </button>
          )}
         
          {(cart === "shopify" || cart === "square" || cart === "magento" || cart === "bigcommerce") && tab === "Products" && (
            <button
              className="btn border-radius-m p-v-s text-white bg-primary"
              onClick={() => {
                switch (cart) {
                  case "shopify":
                    setSyncConfigOpen(true);
                    break;
                  case "magento":
                    MagentoSyncConfig();
                    break;
                  case "square":
                    setSquareSyncConfigOpen(true)
                    break;
                  case "bigcommerce":
                    setBigCommerceConfigOpen(true);
                    break;
                }
              }}
              style={{ marginLeft: "10px" }}
            >
              Map Products
            </button>
          )}

          {cart === "shopify" && tab === "Orders" && (
            <>
              <button
                disabled={apiStatuses.syncShopifyOrderToEcom}
                className="btn border-radius-m p-v-s text-white bg-primary"
                onClick={() => {
                  setShowSyncFromDate(true);
                }}
                style={{ marginLeft: "10px" }}
              >
                <span className="svg-icon svg-baseline mr-2 svg-success">
                  {apiStatuses.syncShopifyOrderToEcom === true ? (
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  ) : (
                    <></>
                  )}
                </span>
                Sync Orders to Ecom
              </button>
              {showSyncFromDate && (
                <SyncByDateRange
                  selectedDate={syncDateRange}
                  onSelect={(newDate) => {
                    const ssd = shopifyConfig?.data?.syncStartDate;
                    const syncStartDate = moment(ssd, yearFirstDateFormat).toDate();
                    if (
                      (newDate?.startDate && newDate.startDate < syncStartDate) ||
                      (newDate?.endDate && newDate.endDate <= syncStartDate)
                    ) {
                      dispatch(
                        addToast({
                          type: "danger",
                          title: "Failure",
                          message: "Selected date should be greater than the Sync Start Date.",
                        })
                      );
                      setSyncDateRange({ startDate: null, endDate: null });
                      return;
                    }
                    setSyncDateRange(newDate);
                  }}
                  syncSubmit={(e) => {
                    setShowSyncFromDate(false);
                    dispatch(
                      addToast({
                        type: "success",
                        title: "Success",
                        message: "Sync job started",
                      })
                    );
                    setApiStatuses((prev) => {
                      return { ...prev, syncShopifyOrderToEcom: true };
                    });
                    const payload = {
                      startDate: Moment(syncDateRange?.startDate).format(startDateFormat),
                      endDate: Moment(syncDateRange?.endDate).format(startDateFormat),
                    };
                    syncShopifyOrdersToEcom(payload)
                      .then((res) => {})
                      .finally(() => {
                        setApiStatuses((prev) => {
                          return { ...prev, syncShopifyOrderToEcom: false };
                        });
                      });
                  }}
                  onClose={(e) => {
                    setShowSyncFromDate(false);
                  }}
                />
              )}
            </>
          )}
          {cart === "shopify" && tab === "Products" && (
            <button
              disabled={apiStatuses.syncShopifyProductsToEcom}
              className="btn border-radius-m p-v-s text-white bg-primary"
              onClick={() => {
                setApiStatuses((prev) => {
                  return { ...prev, syncShopifyProductsToEcom: true };
                });
                syncShopifyProductsToEcom()
                  .then((response) => {})
                  .finally(() => {
                    setApiStatuses((prev) => {
                      return { ...prev, syncShopifyProductsToEcom: false };
                    });
                  });
              }}
              style={{ marginLeft: "10px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-success">
                {apiStatuses.syncShopifyProductsToEcom === true ? (
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                ) : (
                  <></>
                )}
              </span>
              Sync Products to Ecom
            </button>
          )}
          {cart === "shopify" && tab === "Products" && (
            <button
              className="btn border-radius-m p-v-s text-white bg-primary"
              onClick={() => setFileModalOpen(true)}
              style={{ marginLeft: "10px" }}
              // disabled={triggered}
              // onMouseOver={() => setHover(true)}
              // onMouseLeave={() => setHover(false)}
            >
              Import Inventory
            </button>
          )}
        </div>
        <div style={{ verticalAlign: "bottom", marginTop: "7px" }}>
          {triggered && hover && Warning()}
        </div>
        <div className="d-flex align-items-center justify-content-start " style={{ width: "auto" }}>
          {tab !== "MatchingSummaryTable" && tab !== "customFields" && (
            <form className="form-inline ">
              {(cart === "shopify" || cart === "brightpearl") && tab === "Orders" && (
                <button
                  type="button"
                  className="border-radius-m text-wrap-none p-v-s dk-button-hover   border-m bg-white ml-1 position-relative  mr-2 px-2"
                  onClick={() => setShowHideCalendar(true)}
                  style={{ height: "38px" }}
                >
                  <img
                    src={ic_calendar}
                    alt=""
                    className="ic-r ic-s"
                    style={{
                      objectFit: "scale-down",
                      marginBottom: "2px",
                    }}
                  />
                  <span style={{ fontSize: "13px", color: "rgb(45, 43, 37)", marginLeft: "8px" }}>
                    {getDateRangeString()}
                  </span>
                </button>
              )}
              <SearchFilter style={{ marginRight: "6px" }} onFilterMap={onFilter}>
                {(tab === "Orders" ||
                  tab === "Products" ||
                  (tab === "Customers" && cart === "shopify") ||
                  cart === "tally") && (
                  <SearchBar
                    placeholder={`Search ${Utility.convertInTitleCase(tab)}`}
                    inputStyle={{ width: "12rem" }}
                    style={{ minWidth: "10rem", margin: "2px" }}
                    queryKey={"search"}
                  />
                )}
              </SearchFilter>
              {cart === "shopify" && (
                <ShopifyStoreFilter
                  accounts={accounts}
                  customSelectTheme={customSelectTheme}
                  customStyles={customStyles}
                  query={query}
                  setQuery={setQuery}
                />
              )}

              <Select
                placeholder="Filter By Sync Status"
                className="ml-2 mr-3"
                styles={customStyles}
                options={cart === "tally" ? tallyFilterStatusOption : statusOptions}
                // value={statusOptions.filter((option) => option.value === status)}
                menuPlacement="auto"
                onChange={(e) => {
                  updateSelectedStatus(e);
                }}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                isMulti={true}
              />
              {/* <div className="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={handleChange}
                value={value}
              />
            </div> */}
            </form>
          )}
        </div>
      </div>
      <div className="parent-width row position-relative">
        <div className="position-absolute z-index-1" style={{ left:0, top: 0 }}>
          {showHideCalendar && getDateRangePicker()}
        </div>
      </div>
      <div
        className="card"
        style={{
          overflowX: !(cart === "magento" && tab === "customFields") ? "auto" : "hidden",
          height: "100%",
        }}
      >
        <table style={{minHeight:minHeight ?? 'auto'}} className="table m-0 dk-table-hover dk-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{ whiteSpace: "nowrap",width: `${column.width ?? 'auto'}` }}
                  >
                    <span className=" fs-r text-align-left fw-m fs-m text-gray cursor-hand ">
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength + 1}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([{ className: cell.column.className }])}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          {loading && (
            <tr>
              <td colSpan={colummsLength}>
                <div className="d-flex flex-column align-items-center">
                  <span className="mt-2 text-muted">Loading...</span>
                </div>
              </td>
            </tr>
          )}
          {/* {loading && <label className="d-flex justify-content-center">Loading...</label>} */}
        </table>
      </div>
      {!(cart === "magento" && tab === "customFields") && (
        <div className="d-flex flex-row-reverse align-items-center mt-3">
          <div className="d-flex flex-row align-items-center">
            <span className="mr-2">Max rows per page:</span>
            <Select
              placeholder="Page"
              className="page-selector"
              options={pageSizeOptions}
              value={pageSizeOptions.filter((option) => option.value === pageSize)}
              menuPlacement="auto"
              onChange={(e) => {
                setPageSize(Number(e.value));
              }}
              theme={customSelectTheme}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
            />
          </div>
          <div className="d-flex flex-row align-items-center mr-2">
            <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline svg-flipped",
                  canPreviousPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ForwardRight />
              </span>
            </button>
            <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline svg-flipped",
                  canPreviousPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ArrowRight />
              </span>
            </button>
            <span className="mr-2">
              {pageIndex + 1} / {Math.max(pageOptions.length, 1)}
            </span>
            <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline",
                  canNextPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ArrowRight />
              </span>
            </button>
            <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline",
                  canNextPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ForwardRight />
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ServerPaginationTable;
